import { animate, state, style, transition, trigger, } from "@angular/animations";
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { FBCustomer } from "src/app/shared/models/fbcustomer";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { WebOrderDto } from "src/app/shared/models/weborderdto";
import { WebOrderFeedBackDto } from "src/app/shared/models/weborderfeedbackdto";
import { InvoiceHistoryService } from "src/app/shared/services/invoice-history.service";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { InvoicePreviewComponent } from "./invoice-preview/invoice-preview.component";
import { DeliveryFeedBack } from "src/app/shared/models/delivery-feed-back";
import moment from "moment";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { ConfirmationDialogComponent } from "../product/confirmation-dialog/confirmation-dialog.component";
import { AssignShippingDto } from "src/app/shared/models/assign-shipping-dto";

@Component({
  selector: "app-invoice-history",
  templateUrl: "./invoice-history.component.html",
  styleUrls: ["./invoice-history.component.scss"],

  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class InvoiceHistoryComponent implements OnInit {
  dataSource: MatTableDataSource<WebOrderDto>;
  viewWebChildTab: WebOrderDto[] = [];
  viewWebChildTabList: WebOrderDto[] = [];

  resp: boolean = false;
  @ViewChild(MatTable) table: MatTable<any>;
  pageEvent: PageEvent;
  cancelPageEvent: PageEvent;
  length: number;
  pageSizeOptions: number[] = [10, 15, 20];
  pageSize: number;
  pageSiz: number;

  readonly pageItemsSizeCommon: number = 9;
  readonly pageSizeCommon: number = 11;
  readonly pageSize10: number = 10;
  readonly cancelPageSizeCommon: number = 12;

  googleCustomer: GoogleCustomer;
  fbCustomer: FBCustomer;
  viewDataAvail: boolean;

  expandedElement: any;
  InvoiceResponse: string;

  webOrderFeedBackSetup: WebOrderFeedBackDto[] = [];

  isLocationFeedBackDone: boolean = false;
  isDeliveryFeedBackDone: boolean = false;

  locationRateValue = new UntypedFormControl(null, Validators.required);
  deliveryRateValue = new UntypedFormControl(null, Validators.required);

  currentUrl: string;

  // readonly statusList: string[] = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Delivered'];
  currentStep = 1;
  numSteps = 7;

  private onViewSetUpSubscription: Subscription;
  private onWebOrderInvoiceSubscription: Subscription;
  private onFeedbackSetUpSubscription: Subscription;
  private insertFeedBackDeliveryBoy: Subscription;
  private insertFeedBackLocation: Subscription;
  private cancelOrderSubscription: Subscription;

  viewtableColumns: string[] = ["DeliveryDate", "OrderId", "ShippingId", 'OrderAmount', 'ShippingAmount', 'TotalAmount', "otp", "location", "status", "toLocation", "toDeliveryBoy", "download", 'ShippingInvoiceNo'];
  childDivColumnsToDisplay: string[] = ["CustomerName", "MobileNo", "Email", "DeliveryDate", "HouseAddress", "PlotNo", "AreaLocality", "City", "Pincode",];
  childDivColumnsToDisplay1: string[] = ["ProductName", "OfferType", "Qty", "Price",];

  // displayedColumns: string[] = [
  //   "Date",
  //   "Order Id",
  //   "Amount",
  //   "Invoice",
  //   "Delivery Details",
  //   "Feed Back",
  // ];
  // dataSource;

  constructor(
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    public dialog: MatDialog,
    private invoiceHistoryService: InvoiceHistoryService,
    private productOrderSummaryService: ProductOrderSummaryService
  ) {
    this.dataSource = new MatTableDataSource(this.viewWebChildTab);
    this.dataSource.filterPredicate = (data: any, filter) => {
      let dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1;
    };
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.pageSize = this.pageSizeCommon;
    this.viewWebOrderHistory();
    // this.getWebOrderFeedBackDetails();
  }

  getWebOrderFeedBackDetails() {
    this.onFeedbackSetUpSubscription = this.invoiceHistoryService.getWebOrderFeedBackDetails(this.apiFactory.getWebOrderFeedBackDetails)
      .subscribe((data: any) => {
        this.webOrderFeedBackSetup = data;
        console.log(this.webOrderFeedBackSetup);
      });
  }

  selectedTab: { [key: number]: string | null } = {};
  toggleTab(index: number, tab: string) {
    this.selectedTab[index] = this.selectedTab[index] === tab ? null : tab;
    console.log(this.selectedTab[index]);
  }

  @ViewChildren('productList') productLists: QueryList<ElementRef>;
  prevProduct(index: number) {
    const list = this.productLists.toArray()[index]?.nativeElement;
    if (list) {
      list.scrollBy({ left: -300, behavior: 'smooth' });
    }
  }

  nextProduct(index: number) {
    const list = this.productLists.toArray()[index]?.nativeElement;
    if (list) {
      list.scrollBy({ left: 300, behavior: 'smooth' });
    }
  }

  getProductNames(order: any): string {
    if (!order || !order.webOrderDetails) {
      return '';
    }
    return order.webOrderDetails.map((product: any) => product.productName).join(', ');
  }

  viewWebOrderHistory() {
    // Retrieve logged-in customer details
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.fbCustomer = JSON.parse(this.localStorage.getItem("fb_loggedIn_details"));

    // Determine customer email
    const customerEmail = this.googleCustomer?.email || this.fbCustomer?.email;
    if (!customerEmail) {
      this.toastService.showErrorToast("No user logged in");
      return;
    }

    // Fetch order history
    this.onViewSetUpSubscription = this.invoiceHistoryService.getWebOrderDetailsByLoggedInUser(this.apiFactory.getWebOrderDetailsByLoggedInUser, customerEmail)
      .subscribe((data: any) => {
        if (!data || data.length === 0) {
          this.toastService.showErrorToast("No Data Available");
          return;
        }

        // Assign data to required properties
        this.dataSource.data = data;
        this.length = data.totalElements;
        this.viewWebChildTabList = data;
        this.viewWebChildTab = [...data];

        // Process each order
        data.forEach((order: WebOrderDto) => {
          order.isShippingCharge = order.webOrderDetails.some(e => e.productName === 'Shipping Charge');
          this.setStatusListOnData(order);
        });

        console.log(data);
      });
  }

  generateInvoice(locationCode: number, invoiceNo: string, invoiceType: string) {
    var module = invoiceType;
    var invNo = invoiceNo;
    var locCode = locationCode;

    let dataToPrint: string[] = [];
    dataToPrint[0] = locationCode.toString();
    dataToPrint[1] = invoiceNo;
    dataToPrint[2] = invoiceType;
    dataToPrint[3] = this.currentUrl;

    this.dialog.open(InvoicePreviewComponent, {
      height: "75%",
      data: dataToPrint,
    });

    // this.onWebOrderInvoiceSubscription = this.productOrderSummaryService.getWebOrderInvoiceDetails(this.apiFactory.getWebOrderInvoiceDetails, module, invNo, locCode)
    //   .subscribe((data) => {
    //     this.InvoiceResponse = data;
    //     this.downloadPdf(this.InvoiceResponse);
    //   });
  }

  onLocationRateChange(webOrder: WebOrderDto) {
    if (!this.insertFeedBackLocation && !webOrder.deliveryFeedBackWebDto?.isLocationFeedBackDone) {
      console.log(this.locationRateValue.value);
      let locationRating: DeliveryFeedBack = new DeliveryFeedBack();
      locationRating.assignedNo = webOrder.assignShippingDto?.refNo;
      locationRating.feedbackBy = webOrder.customer.refNo;
      locationRating.feedbackDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      locationRating.feedbackFor = "Location";
      locationRating.locationCode = webOrder.location?.refNo;
      locationRating.toLocation = this.locationRateValue.value;
      locationRating.orderNo = webOrder.orderNo;

      console.log(locationRating);

      this.insertFeedBackLocation = this.invoiceHistoryService.insertFeedBack(this.apiFactory.insertFeedBack, [locationRating])
        .subscribe((data: any) => {
          this.insertFeedBackLocation = undefined;
          console.log(data);
          if (data != null) {
            this.isLocationFeedBackDone = true;
            webOrder.deliveryFeedBackWebDto.isLocationFeedBackDone = true;
            this.toastService.showSuccessToast("FeedBack Saved Sucessfully");
          }
        });
    }
  }

  onDeliveryRateChange(webOrder: WebOrderDto) {
    if (!this.insertFeedBackDeliveryBoy && !webOrder.deliveryFeedBackWebDto?.isDeliveryFeedBackDone) {
      let deliveryBoyRating: DeliveryFeedBack = new DeliveryFeedBack();
      deliveryBoyRating.assignedNo = webOrder.assignShippingDto?.refNo;
      deliveryBoyRating.feedbackBy = webOrder.customer.refNo;
      deliveryBoyRating.feedbackDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      deliveryBoyRating.feedbackFor = "Delivery Boy";
      deliveryBoyRating.locationCode = webOrder.location?.refNo;
      deliveryBoyRating.toDeliveryBoy = this.deliveryRateValue.value;
      deliveryBoyRating.orderNo = webOrder.orderNo;

      console.log(deliveryBoyRating);


      this.insertFeedBackDeliveryBoy = this.invoiceHistoryService.insertFeedBack(this.apiFactory.insertFeedBack, [deliveryBoyRating])
        .subscribe((data: any) => {
          this.insertFeedBackDeliveryBoy = undefined;
          console.log(data);
          if (data != null) {
            this.isDeliveryFeedBackDone = true;
            webOrder.deliveryFeedBackWebDto.isDeliveryFeedBackDone = true;
            this.toastService.showSuccessToast("FeedBack Saved Sucessfully");
          }
        });
    }
  }

  setStatusListOnData(element: WebOrderDto) {
    element.statusList = [];

    // Determine initial invStatus
    let invStatus: string = element.currentStatus === 'Confirmed' ? 'Order Placed' : element.currentStatus === 'Declined' ? 'Cancelled'
      : (element.invoiceDetails[0].invoiceType !== 'Make To Order' && element.invoiceDetails[0].invStatus !== 'Cancelled' && !element.assignShippingDto
        && !element.dispatchStorePickupDto) || (!element.assignShippingDto && ['Billed', 'Received'].includes(element.invoiceDetails[0].invStatus))
        ? 'Received at location' : element.assignShippingDto && ['Billed', 'Received'].includes(element.invoiceDetails[0].invStatus)
          ? 'Confirmed' : element.invoiceDetails[0]?.invStatus;

    // Determine trackingStatus
    let status: string = (invStatus == 'Confirmed' || invStatus == 'Ordered' || invStatus == 'Declined' || element.currentStatus == 'Accepted') && element.assignShippingDto == null && element.dispatchStorePickupDto == null ? 'Accepted'
      : invStatus == 'Confirmed' && (element.assignShippingDto?.status == 'Assigned' || element.assignShippingDto?.status == 'Re-Assigned' || element.assignShippingDto?.status == 'Rider Declined' || element.assignShippingDto?.status == 'Pick-Up-Cancelled' || element.assignShippingDto?.status == 'Ready To Ship') ? 'Received at location'
        : invStatus == 'Confirmed' && (element.assignShippingDto?.status == 'Rider Accepted') ? 'Rider assigned'
          : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Picked-Up' ? 'Out for Delivery'
            : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Delivered' ? 'Delivered'
              : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Re-Scheduled' ? 'Rescheduled'
                : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Delivery Cancelled' ? 'Cancelled'
                  : invStatus == 'Dispatched' && element.assignShippingDto == null ? 'Dispatched to location'
                    : invStatus == 'Cancelled' && element.assignShippingDto == null ? 'Order Cancelled'
                      : invStatus != 'Confirmed' && invStatus != 'Ordered' ? invStatus
                        : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Dispatched' ? 'Dispatched to location'
                          : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Received' ? 'Received at location'
                            : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Delivered' ? 'Delivered'
                              : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Return' ? 'Returned'
                                : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Return Received' ? 'Returned'
                                  : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Delivered' ? 'Delivered'
                                    : '';

    // Special handling for Unfulfilled and Refund cases
    if (element.orderStatus === 'Unfulfilled') {
      if (element.refundStatus === 'Refunded via Wallet') {
        element.trackingStatus = 'Refunded via Wallet';
        element.statusList = ['Order Placed', 'Accepted', 'Unfulfilled', 'Refund Initiated via Wallet', 'Refunded via Wallet'];
      } else if (element.refundStatus === 'Refunded via Gateway') {
        element.trackingStatus = 'Refunded via Gateway';
        element.statusList = ['Order Placed', 'Accepted', 'Unfulfilled', 'Refund Initiated via Gateway', 'Refunded via Gateway'];
      } else if (element.refundStatus === 'Refund Initiated via Wallet') {
        element.trackingStatus = 'Refund Initiated via Wallet';
        element.statusList = ['Order Placed', 'Accepted', 'Unfulfilled', 'Refund Initiated via Wallet', 'Refunded via Wallet'];
      } else if (element.refundStatus === 'Refund Initiated via Gateway') {
        element.trackingStatus = 'Refund Initiated via Gateway';
        element.statusList = ['Order Placed', 'Accepted', 'Unfulfilled', 'Refund Initiated via Gateway', 'Refunded via Gateway'];
      } else {
        element.trackingStatus = 'Order Unfulfilled';
        element.statusList = ['Order Placed', 'Accepted', 'Unfulfilled'];
      }
    } else {
      element.trackingStatus = status;

      if (!element.customerDeliveryDetails) {
        element.statusList = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Delivered'];
      } else {
        switch (status) {
          case 'Order Cancelled':
            element.statusList = ['Order Placed', 'Order Cancelled', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Delivered'];
            break;
          case 'Rescheduled':
            element.statusList = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Rescheduled', 'Delivered'];
            break;
          case 'Cancelled':
            element.statusList = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Cancelled', 'Delivered'];
            break;
          default:
            element.statusList = this.statusList; // Use default statusList
        }
      }
    }
  }

  // Default statusList
  readonly statusList: string[] = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Delivered'];

  stepperStatus: { [orderNo: string]: number } = {};

  onStatusClick(element: WebOrderDto) {
    // Find the current step based on trackingStatus
    this.stepperStatus[element.orderNo] = element.statusList.findIndex(e => e === element.trackingStatus);
    let currentStep = this.stepperStatus[element.orderNo];

    setTimeout(() => {
      const stepper = document.getElementById("index_step_" + element.orderNo);
      if (!stepper) {
        console.error(`Stepper element with id "index_step_${element.orderNo}" not found.`);
        return;
      }

      const steps = stepper.getElementsByClassName("step");

      Array.from(steps).forEach((step, index) => {
        let stepNum = index;

        if (stepNum < currentStep) {
          this.addClass(step, "done");
          this.removeClass(step, "editing");
        } else if (stepNum === currentStep) {
          this.addClass(step, "done");
          this.addClass(step, "editing");
        } else {
          this.removeClass(step, "done");
          this.removeClass(step, "editing");
        }
      });
    }, 0);
  }

  hasClass(elem, className) {
    return new RegExp(" " + className + " ").test(" " + elem.className + " ");
  }

  addClass(elem, className) {
    if (!this.hasClass(elem, className)) {
      elem.className += " " + className;
    }
  }

  removeClass(elem, className) {
    var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " ";
    if (this.hasClass(elem, className)) {
      while (newClass.indexOf(" " + className + " ") >= 0) {
        newClass = newClass.replace(" " + className + " ", " ");
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, "");
    }
  }

  onOrderCancelClick(element: WebOrderDto) {
    console.log(element)
    if (element.trackingStatus == 'Order Placed') {
      let headingMessage = "Are you sure want to cancel order?";
      let subMessage = "Money will be refunded";

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '187px',
        width: '346px',
        data: { heading: headingMessage, message: subMessage }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.cancelOrderSubscription = this.invoiceHistoryService.cancelOrder(this.apiFactory.cancelOrderByInvNo, element.orderNo, "Cancelled").subscribe((data) => {
            if (data) {
              this.toastService.showSuccessToast("Order cancelled successfully");
              this.viewWebOrderHistory();
            }
          });
        }
      })
    }
    else {
    }
  }

  // setStatusListOnData(element: WebOrderDto) {
  //   element.statusList = [];

  //   let invStatus: string = element.currentStatus == 'Confirmed' ? 'Order Placed' : element.currentStatus == 'Declined' ? 'Cancelled' :
  //     (element.invoiceDetails[0].invoiceType != 'Make To Order' && element.invoiceDetails[0].invStatus != 'Cancelled' && element.assignShippingDto == null && element.dispatchStorePickupDto == null) ||
  //       (element.assignShippingDto == null && ['Billed', 'Received'].includes(element.invoiceDetails[0].invStatus)) ? 'Received at location' :
  //       (element.assignShippingDto != null && ['Billed', 'Received'].includes(element.invoiceDetails[0].invStatus)) ? 'Confirmed' : element.invoiceDetails[0]?.invStatus;

  //   let status: string = (invStatus == 'Confirmed' || invStatus == 'Ordered' || invStatus == 'Declined' || element.currentStatus == 'Accepted') && element.assignShippingDto == null && element.dispatchStorePickupDto == null ? 'Accepted'
  //     : invStatus == 'Confirmed' && (element.assignShippingDto?.status == 'Assigned' || element.assignShippingDto?.status == 'Re-Assigned' || element.assignShippingDto?.status == 'Rider Declined' || element.assignShippingDto?.status == 'Pick-Up-Cancelled' || element.assignShippingDto?.status == 'Ready To Ship') ? 'Received at location'
  //       : invStatus == 'Confirmed' && (element.assignShippingDto?.status == 'Rider Accepted') ? 'Rider assigned'
  //         : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Picked-Up' ? 'Out for Delivery'
  //           : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Delivered' ? 'Delivered'
  //             : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Re-Scheduled' ? 'Rescheduled'
  //               : invStatus == 'Confirmed' && element.assignShippingDto?.status == 'Delivery Cancelled' ? 'Cancelled'
  //                 : invStatus == 'Dispatched' && element.assignShippingDto == null ? 'Dispatched to location'
  //                   : invStatus == 'Cancelled' && element.assignShippingDto == null ? 'Order Cancelled'
  //                     : invStatus != 'Confirmed' && invStatus != 'Ordered' ? invStatus
  //                       : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Dispatched' ? 'Dispatched to location'
  //                         : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Received' ? 'Received at location'
  //                           : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Delivered' ? 'Delivered'
  //                             : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Return' ? 'Returned'
  //                               : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Return Received' ? 'Returned'
  //                                 : invStatus == 'Confirmed' && element.dispatchStorePickupDto?.status == 'Delivered' ? 'Delivered'
  //                                   : '';

  //   element.trackingStatus = status;
  //   if (status == 'Order Cancelled') {
  //     element.statusList = ['Order Placed', 'Order Cancelled', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Delivered'];
  //   }
  //   else if (status == 'Rescheduled') {
  //     element.statusList = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Rescheduled', 'Delivered'];
  //   }
  //   else if (status == 'Cancelled') {
  //     element.statusList = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Rider assigned', 'Out for Delivery', 'Cancelled', 'Delivered'];
  //   }
  //   else if (!element.customerDeliveryDetails && status == 'Delivered') {
  //     element.statusList = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Delivered'];
  //   }
  //   else if (!element.customerDeliveryDetails && status == 'Returned') {
  //     element.statusList = ['Order Placed', 'Accepted', 'In Process', 'Production Done', 'Dispatched to location', 'Received at location', 'Returned'];
  //   } else {
  //     element.statusList = this.statusList;
  //   }
  // }

  // downloadPdf(base64: string) {
  //   const byteArray = new Uint8Array(
  //     atob(base64)
  //       .split("")
  //       .map((char) => char.charCodeAt(0))
  //   );
  //   const file = new Blob([byteArray], { type: "application/pdf" });
  //   const fileURL = URL.createObjectURL(file);
  //   let pdfName = "invoice_report.pdf";
  //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveOrOpenBlob(file, pdfName);
  //   } else {
  //     //window.open(fileURL);

  //     // Construct the 'a' element
  //     let link = document.createElement("a");
  //     link.download = pdfName;
  //     link.target = "_blank";

  //     // Construct the URI
  //     link.href = fileURL;
  //     document.body.appendChild(link);
  //     link.click();

  //     // Cleanup the DOM
  //     document.body.removeChild(link);
  //   }
  // }
}
